<template>
  <div class="home">
    <Header />
    <SectionHeader />
    <SectionAbout />
    <SectionAction />
    <SectionSteps />
    <SectionOffers />
    <SectionContacts />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../components/Header.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import SectionAbout from "@/components/SectionAbout.vue";
import SectionAction from "@/components/SectionAction.vue";
import SectionSteps from "@/components/SectionSteps.vue";
import SectionOffers from "@/components/SectionOffers.vue";
import SectionContacts from "../components/SectionContacts.vue";
export default {
  name: "Home",
  components: {
    Header,
    SectionHeader,
    SectionAbout,
    SectionAction,
    SectionSteps,
    SectionOffers,
    SectionContacts,
  },
};
</script>

<style scoped></style>
