<template>
  <div class="section-header">
    <div class="container">
      <div class="section-info">
        <div>
          <h1>
            Готовое решение <br />
            для вашео бизнеса
          </h1>
          <p>
            Мы убрали все лишнее и сфокусировались на главном, создав
            оптимальное решение воронки продаж ваших товаров
          </p>
          <div>
            <button class="btn btn-danger">Заказать</button>
          </div>
        </div>
        <div>
          <img src="@/assets/wolt-clone-banner.webp" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionHeader",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.section-header {
  padding: 100px 0 4em;
  background: rgb(16, 28, 230);
  background: linear-gradient(
    45deg,
    rgba(16, 28, 230, 1) 0%,
    rgba(26, 137, 217, 1) 35%,
    rgba(17, 205, 240, 1) 100%
  );
}
.section-header h1,
.section-header p {
  color: #fff;
}
.section-info {
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
}
.section-info div img {
  object-fit: cover;
  width: 100%;
}
@media (max-width: 768px) {
  .section-info {
    display: grid;
    grid-template-columns: 100%;
  }
}
</style>
